import React from "react"
import { Helmet } from "react-helmet"

import AthenaFront from "../images/athena/front.jpg"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Foot from "../components/foot"

import CarouselImg from "../components/carousel-img"
import ImagesAthena from "../images/images-athena"
import ImagesTavel from "../images/images-tavel"

import Accordion from "react-bootstrap/Accordion"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

const OurLocations = () => (
  <Layout>
    <SEO title="Our Locations" />
    <Helmet>
      <meta property="og:image" content={ AthenaFront } />
    </Helmet>
    <Container className="mt-4">
      <h3>Our Locations</h3>

      <Accordion className="mt-4" alwaysOpen>

        <Accordion.Item eventKey="0">
          <Accordion.Header>Athena</Accordion.Header>
          <Accordion.Body>
            <Row className="ms-0 mt-1">
              <h5>Grace Home Athena</h5>
            </Row> 

            <Row className="mt-2" style={{fontSize: "12pt"}}>
              <Col xs={12} md>
                <ul className="ms-0">
                  <li>Single story residence</li>
                  <li>Cozy front courtyard with gate</li>
                  <li>Large living room with fireplace and mounted TV</li>
                  <li>Luxury plank flooring throughout</li>
                  <li>Beautiful dining room</li>
                  <li>Game tables</li>
                  <li>Gourmet kitchen</li>
                </ul>
              </Col>
              <Col xs={12} md>
                <ul className="ms-0">
                  <li>6 Bedrooms (each with ceiling fan and flat screen TV)</li>
                  <li>4 Bathrooms (3 are en-suite)</li>
                  <li>Tranquil backyard with BBQ, fountain, firepit, and ample seating</li>
                  <li>Walking path</li>
                  <li>Gardening boxes and fruit trees</li>
                </ul>
              </Col>
            </Row>

            <Container className="p-0 mt-2">
              <CarouselImg images={ImagesAthena}/>
            </Container>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>Tavel</Accordion.Header>
          <Accordion.Body>
            <Row className="ms-0 mt-1">
              <h5>Grace Home Tavel</h5>
            </Row>

            <Row className="mt-2" style={{fontSize: "12pt"}}>
              <Col xs={12} md>
                <ul className="ms-0">
                  <li>Single story residence</li>
                  <li>Large front porch with cover</li>
                  <li>Open and spacious living room with flat screen TV</li>
                  <li>Cozy dining room overlooking the beautiful backyard</li>
                  <li>Luxury plank flooring throughout</li>
                </ul>
              </Col>
              <Col xs={12} md>
                <ul className="ms-0">
                  <li>5 Bedrooms (each with ceiling fan and flat screen TV; 2 with french doors to yard)</li>
                  <li>3 Bathrooms</li>
                  <li>Very large backyard with 2 large covered patios allowing ample seating. Built-in BBQ, waterfall, and firepit</li>
                  <li>Large side yard with walking path</li>
                  <li>Gardening boxes and fruit trees</li>
                </ul>
              </Col>
            </Row>

            <Container className="p-0 mt-2">
              <CarouselImg images={ImagesTavel} />
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <div style={{marginTop: "291px"}}>
        <Foot />
      </div>
      
    </Container>
  </Layout>
)

export default OurLocations