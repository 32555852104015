import Bathroom1 from "./athena/bathroom-1.jpg"
import Bathroom2 from "./athena/bathroom-2.jpg"
import Bedroom1 from "./athena/bedroom-1.jpg"
import Bedroom2 from "./athena/bedroom-2.jpg"
import Bedroom3 from "./athena/bedroom-3.jpg"
import ResidentsBirthday from "./athena/birthday.jpg"
import Dining from "./athena/dining.jpg"
import Front from "./athena/front.jpg"
import Kitchen from "./athena/kitchen.jpg"
import LivingRoom from "./athena/living-room.jpg"
import ResidentsLivingRoom from "./athena/living-room-residents.jpg"
import Message from "./athena/message.jpg"
import ResidentsOutsidePuzzle from "./athena/outside-puzzle.jpg"
import ResidentsOutsideArt from "./athena/outsize-art.jpg"
import Patio from "./athena/patio.jpg"
import ResidentsPuzzle from "./athena/puzzle.jpg"
import Worship1 from "./athena/worship-1.jpg"
import Worship2 from "./athena/worship-2.jpg"

const ImagesAthena = [
  {
    id: 1,
    src: Front,
    alt: "Front"
  },
  {
    id: 2,
    src: LivingRoom,
    alt: "Living room"
  },
  {
    id: 3,
    src: ResidentsPuzzle,
    alt: "Residents completing a jigsaw puzzle"
  },
  {
    id: 4,
    src: Bedroom1,
    alt: "Bedroom 1"
  },
  {
    id: 5,
    src: Bathroom1,
    alt: "Bathroom 1"
  },
  {
    id: 6,
    src: ResidentsBirthday,
    alt: "Residents celebrating a birthday"
  },
  {
    id: 7,
    src: Kitchen,
    alt: "Kitchen"
  },
  {
    id: 8,
    src: Dining,
    alt: "Dining room"
  },
  {
    id: 9,
    src: Worship1,
    alt: "Residents watch worship performance, view 1"
  },
  {
    id: 10,
    src: Worship2,
    alt: "Residents watch worship performance, view 2"
  },
  {
    id: 11,
    src: Bedroom2,
    alt: "Bedroom 2"
  },
  {
    id: 12,
    src: ResidentsLivingRoom,
    alt: "Residents in living room"
  },
  
  {
    id: 13,
    src: Bedroom3,
    alt: "Bedroom 3"
  },
  {
    id: 14,
    src: Bathroom2,
    alt: "Bathroom2"
  },
  
  {
    id: 15,
    src: Message,
    alt: "Residents watching a Sunday message"
  },
  {
    id: 16,
    src: Patio,
    alt: "Athena patio"
  },
  {
    id: 17,
    src: ResidentsOutsidePuzzle,
    alt: "Residents playing games on patio table"
  },
  {
    id: 18,
    src: ResidentsOutsideArt,
    alt: "Residents working on art projects outside"
  }
]

export default ImagesAthena