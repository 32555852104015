import React from "react"

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel"

export default function CarouselImg({images}) {
  return (
    <Carousel autoPlay showArrows showIndicators={false} dynamicHeight infiniteLoop stopOnHover swipeable>
      {images.map((item) => (
        <div> <img src={item.src} alt={item.alt} /> </div>
      ))}
    </Carousel>
  )
}
