import Back1 from "./tavel/back-1.jpg"
import Back2 from "./tavel/back-2.jpg"
import Back3 from "./tavel/back-3.jpg"
import Bathroom1 from "./tavel/bathroom-1.jpg"
import Bathroom2 from "./tavel/bathroom-2.jpg"
import Bathroom3 from "./tavel/bathroom-3.jpg"
import Bathroom4 from "./tavel/bathroom-4.jpg"
import Bedroom1_1 from "./tavel/bedroom-1.1.jpg"
import Bedroom1_2 from "./tavel/bedroom-1.2.jpg"
import Bedroom2_1 from "./tavel/bedroom-2.1.jpg"
import Bedroom2_2 from "./tavel/bedroom-2.2.jpg"
import Bedroom3 from "./tavel/bedroom-3.jpg"
import Bedroom4_1 from "./tavel/bedroom-4.1.jpg"
import Bedroom4_2 from "./tavel/bedroom-4.2.jpg"
import Dining from "./tavel/dining.jpg"
import Front1 from "./tavel/front-1.jpg"
import Front2 from "./tavel/front-2.jpg"
import Kitchen from "./tavel/kitchen.jpg"
import LivingRoom from "./tavel/living-room.jpg"
import Porch from "./tavel/porch.jpg"
import Side1 from "./tavel/side-1.jpg"
import Side2 from "./tavel/side-2.jpg"
import SittingRoom from "./tavel/sitting-room.jpg"

const ImagesTavel = [
  {
    id: 1,
    src: Front1,
    alt: "Front, view 1"
  },
  {
    id: 2,
    src: Front2,
    alt: "Front, view 2"
  },
  {
    id: 3,
    src: LivingRoom,
    alt: "Living room"
  },
  {
    id: 4,
    src: Bedroom1_1,
    alt: "Bedroom 1, view 1"
  },
  {
    id: 5,
    src: Bedroom1_2,
    alt: "Bedroom 2, view 2"
  },
  {
    id: 6,
    src: Bathroom1,
    alt: "Bathroom 1"
  },
  {
    id: 7,
    src: Bathroom2,
    alt: "Bathroom 2"
  },
  {
    id: 8,
    src: Kitchen,
    alt: "Kitchen"
  },
  {
    id: 9,
    src: Porch,
    alt: "Porch"
  },
  {
    id: 10,
    src: Side1,
    alt: "Side yard, view 1"
  },
  {
    id: 11,
    src: Side2,
    alt: "Side yard, view 2"
  },
  {
    id: 12,
    src: Bedroom2_1,
    alt: "Bedroom 2, view 1"
  },
  {
    id: 13,
    src: Bedroom2_2,
    alt: "Bedroom 2, view 2"
  },
  {
    id: 14,
    src: Bathroom3,
    alt: "Bathroom 3"
  },
  {
    id: 15,
    src: Dining,
    alt: "Dining table"
  },
  {
    id: 16,
    src: SittingRoom,
    alt: "Sitting room"
  },
  {
    id: 17,
    src: Bedroom3,
    alt: "Bedroom 3"
  },
  {
    id: 18,
    src: Bathroom4,
    alt: "Bathroom 4"
  },
  {
    id: 19,
    src: Bedroom4_1,
    alt: "Bedroom 4, view 1"
  },
  {
    id: 20,
    src: Bedroom4_2,
    alt: "Bedroom 4, view 2"
  },
  {
    id: 21,
    src: Back1,
    alt: "Backyard, view 1"
  },
  {
    id: 22,
    src: Back2,
    alt: "Backyard, view 2"
  },
  {
    id: 23,
    src: Back3,
    alt: "Backyard, view 3"
  },
]

export default ImagesTavel